import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncementDeliveryStatusService } from 'app/announcement-delivery-status.service';
import { Helper } from 'app/common/helper';
import {
  ActiveColumnHeader,
  Constant,
  DeviceDetailStatusEnum,
  DeviceStatusEnum,
  PayloadDeviceStatusEnum,
  StatusNumberObject
} from 'app/config/constants';
import { AnnouncementDeliveryStatus } from 'app/model/entity/announcement/announcement-delivery-status';
import { AnnouncementPlaylist } from 'app/model/entity/announcement/announcement-playlist';
import { DeviceCommonSpecific } from 'app/model/entity/announcement/device-common-specific';
import { GroupDeviceAnnouncement } from 'app/model/entity/announcement/group-device-announcement';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { ResponsePublish } from 'app/model/entity/publish-info-timetable';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DevicePublishInfoService } from 'app/service/device-publish-info.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import _ from 'lodash';
import { Subject, forkJoin, interval } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogDownloadDataComponent } from '../dialog-download-data/dialog-download-data.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DialogSimpleSignageMessageComponent } from '../dialog-simple-signage-message/dialog-simple-signage-message.component';

@Component({
  selector: 'dialog-delivery-announcement',
  templateUrl: './dialog-delivery-announcement.component.html',
  styleUrls: ['./dialog-delivery-announcement.component.scss']
})
export class DialogDeliveryAnnouncementComponent implements OnInit {
  /**
   * common object
   */
  private commonObject: Common;
  /**
   * subject
   */
  private subject$ = new Subject();
  /**
   * DeviceStatusEnum
   */
  DeviceStatusEnum = DeviceStatusEnum;
  /**
   * constant
   */

  public readonly ActiveColumnHeader = ActiveColumnHeader;
  private readonly LENGTH_ACCOUNT_OLD = 5;
  private readonly ACCOUNT_OLD = 'T00000';
  private readonly DELIVERY_FAILED = 'Delivery failed.';
  private readonly WAITING_ELEMENT = 'waiting';
  private readonly IN_PROGRESS_ELEMENT = 'inProcess';
  private readonly SUCCESSFUL_ELEMENT = 'successed';
  private readonly CANCEL_ELEMENT = 'canceled';
  private readonly FAILED_ELEMENT = 'failed';
  private readonly STATUS_ELEMENT = 'status';
  private readonly JOB_ID = 'jobId';
  private readonly DEVICE_LIST_ELEMENT = 'deviceList';
  private readonly GROUP_DEVICES = 'groupDevicesSimple';
  private readonly REGISTRATION_ID_ELEMENT = 'registrationId';
  private readonly JOB_ID_SPLIT_KEY = '---'; // Copied from dialog-delivery-timetable.component.ts

  /**
   * interval update status for devices
   */
  private intervalUpdateStatusArray: Array<DeliveryGroupIntervalObject> = new Array<DeliveryGroupIntervalObject>();
  /**
   * interval update status for devices
   */
  intervalUpdateStatusForDevices: any;
  /**
   * Group expanded
   */
  public groupExpanded: GroupDeviceAnnouncement;
  /**
   * true if group exists device delivery group
   */
  public isDeliveryGroup: boolean;
  /**
   * true if disable icon eye
   */
  public isShowDetailStatus: boolean;
  /**
   * device common specifics
   */
  deviceCommonSpecifics: Array<DeviceCommonSpecific> = new Array();
  /**
   * group devices
   */
  groupDevices: Array<GroupDeviceAnnouncement>;

  /**
   * Group expanded
   */
  public groupExpandedClone: GroupDeviceAnnouncement;
  /**
   * true if all device checked
   */
  isCheckingAll: boolean;

  /**
   * Cancelled devices for cancelDelivery() and deliveryUpload()
   */
  private cancelledDeviceIds: Array<number> = new Array<number>();
  /**
   * Group IDs when the user partially cancel the group delivery
   * e.g. GroupA = [10000, 10001, 10002]
   * -> cancel 10001
   * -> groupBreakdownNames.append('GroupA');
   * -> will use single delivery instead of group delivery for [10000, 10002]
   */
  private groupBreakdownNames: Array<string> = new Array<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogDeliveryAnnouncementComponent>,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private dataService: DataService,
    private commonService: CommonService,
    private commonTableService: CommonTableService,
    private http: HttpClient,
    private executingService: ExecutingService,
    private apiCustomerService: APICustomerService,
    private announcementDeliveryStatusService: AnnouncementDeliveryStatusService,
    private devicePublishInfoService: DevicePublishInfoService
  ) {
    this.commonObject = this.commonService.getCommonObject();
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.DELIVERY_SUCCESS) {
        if (!this.groupDevices || !this.dialogRef.componentInstance) {
          return;
        }
        this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
        this.clearAllIntervalDeliveryGroup();
        let devicesOld = data[1] as DeviceCommonSpecific[];
        this.groupDevices.forEach(group => {
          group.deviceCommonSpecifics.forEach(device => {
            let deviceDeliverySuccess = devicesOld.find(deviceOld => deviceOld.registrationId == device.registrationId);
            if (deviceDeliverySuccess) {
              device.status = deviceDeliverySuccess.status;
              device.jobId = deviceDeliverySuccess.jobId;
              if (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) != -1) {
                group.groupJobId = device.jobId;
              }
              this.updateNumberStatusFailed(group);
              if (this.groupExpandedClone) {
                let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(
                  deviceClone => deviceClone.registrationId == device.registrationId
                );
                if (index != -1) {
                  this.groupExpandedClone.deviceCommonSpecifics[index].status = device.status;
                  this.groupExpandedClone.deviceCommonSpecifics[index].jobId = device.jobId;
                }
                if (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) != -1) {
                  this.groupExpandedClone.groupJobId = device.jobId;
                }
                this.updateNumberStatusFailed(this.groupExpandedClone);
              }
            }
          });
        });
        this.handleUpdateStatusForDevices();
      }
    });
  }

  ngOnInit(): void {
    this.groupDevices = this.data.groupDevices;
    if (this.commonObject[this.GROUP_DEVICES]) {
      this.groupDevices.forEach(group => {
        let groupOld = this.commonObject[this.GROUP_DEVICES].find(item => item.name == group.name);
        if (groupOld) {
          group.isExpand = false;
          group.isChecking = false;
          group.activeColumnHeader = ActiveColumnHeader.TOTAL;
          this.deviceCommonSpecifics = this.deviceCommonSpecifics.concat(group.deviceCommonSpecifics);
          group.statusNumberObject = groupOld.statusNumberObject;
          if (group.groupId) {
            group.deviceCommonSpecifics.forEach(data => (data.groupId = group.groupId));
          }
        }
      });
    } else {
      this.groupDevices?.forEach(group => {
        group.isExpand = false;
        group.isChecking = false;
        group.activeColumnHeader = ActiveColumnHeader.TOTAL;
        this.deviceCommonSpecifics = this.deviceCommonSpecifics.concat(group.deviceCommonSpecifics);
        group.statusNumberObject = new StatusNumberObject();
        group.statusNumberObject.total = group.deviceCommonSpecifics.length;
        if (group.groupId) {
          group.deviceCommonSpecifics.forEach(data => (data.groupId = group.groupId));
        }
      });
    }
    this.deviceCommonSpecifics?.forEach(deviceData => (deviceData.isChecking = false));
    this.getStatusForAllDeviceFirstTime();
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
  }
  /**
   * get status for all device
   */
  private getStatusForAllDeviceFirstTime(): void {
    this.announcementDeliveryStatusService.getDeliveryStatusForDevices().subscribe(
      async data => {
        if (!data) {
          return;
        }

        for (let i = 0; i < this.groupDevices.length; i++) {
          let group = this.groupDevices[i];
          group.deviceCommonSpecifics.forEach(device => {
            const index = data.findIndex(item => item.deviceId == device.id);
            if (index != -1) {
              device.jobId = data[index].jobId;
              device.status = data[index].status;
            }
          });
          if (group.groupId) {
            let groupJobId = this.getGroupJobId(
              group.deviceCommonSpecifics.map(device => device.id),
              data
            );
            if (groupJobId) {
              group.groupJobId = groupJobId;
              let payload = {
                jobId: groupJobId
              };
              await new Promise<void>(resolve => {
                this.apiCustomerService
                  .jobProcessDetails(payload)
                  .toPromise()
                  .then(
                    data => {
                      if (data) {
                        this.updateStatusNumberObjectForGroup(data, group);
                        if (
                          this.checkStatusDevicesGroup(group.deviceCommonSpecifics) &&
                          Helper.checkMappingStatusDevicesGroupAnnouncement(group, this.groupExpandedClone)
                        ) {
                          group.statusNumberObjectOld = _.cloneDeep(group.statusNumberObject);
                          resolve();
                          return;
                        }
                        this.handleStatusForDeviceOfGroup(group);
                        group.statusNumberObjectOld = _.cloneDeep(group.statusNumberObject);
                      }
                      resolve();
                    },
                    () => resolve()
                  );
              });
            } else {
              this.updateNumberStatusFailed(group);
            }
          }
        }
        this.handleUpdateStatusForDevices();
      },
      error => this.handleErrorMessage(error, 'get-status-for-devices-failed')
    );
  }

  /**
   * Check status devices group
   *
   * @param deviceCommonSpecific
   * @returns
   */
  private checkStatusDevicesGroup(deviceCommonSpecific: DeviceCommonSpecific[]): boolean {
    if (!deviceCommonSpecific) {
      return true;
    }
    return !deviceCommonSpecific.some(device => device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS);
  }

  /**
   *
   * Get group job id;
   * @param deviceGroupsId
   * @param deliveryStatusTimetables
   * @returns
   */
  private getGroupJobId(deviceGroupsId: Number[], deliveryStatusTimetables: AnnouncementDeliveryStatus[]): string {
    let jobIdsOfGroup = deliveryStatusTimetables
      .filter(data => deviceGroupsId.includes(data.deviceId))
      .filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
      .map(deliveryStatus => deliveryStatus.jobId);
    return jobIdsOfGroup[0];
  }

  /**
   * check group opened
   * @returns
   */
  public checkGroupOpened(): any {
    return this.groupDevices.some(group => group?.isExpand);
  }

  /**
   * handle update status for devices
   */
  private async handleUpdateStatusForDevices(): Promise<void> {
    this.groupDevices.forEach(group => {
      if (group.groupId) {
        this.intervalUpdateStatusArray.push(
          new DeliveryGroupIntervalObject(this.handleUpdateStatusForDevicesGroupInterval(group), group.groupId)
        );
      }
    });
    this.intervalUpdateStatusForDevices = this.handleUpdateStatusForDevicesInterval();
  }

  /**
   * Handle update status for devices group interval
   *
   * @param group
   * @returns
   */
  private handleUpdateStatusForDevicesGroupInterval(group: GroupDeviceAnnouncement): any {
    return interval(1000)
      .pipe(
        concatMap(() => {
          return this.updateStatusForDevicesGroup(group);
        })
      )
      .subscribe();
  }

  /**
   * Update status for devices group
   *
   * @param groupDevice
   * @returns
   */
  private updateStatusForDevicesGroup(groupDevice: GroupDeviceAnnouncement): Promise<void> {
    this.checkGroupExistsDeviceDeliveryGroup();
    if (
      !groupDevice.groupJobId ||
      !groupDevice.groupJobId.length ||
      (this.checkStatusCallJobDetail(groupDevice) &&
        Helper.checkMappingStatusDevicesGroupAnnouncement(groupDevice, this.groupExpandedClone)) ||
      groupDevice.deviceCommonSpecifics.some(device => device.isDelivering)
    ) {
      this.clearIntervalForGroup(groupDevice.groupId);
      return;
    }
    const payload = {
      jobId: groupDevice.groupJobId
    };
    return new Promise<void>(resolve => {
      this.apiCustomerService
        .jobProcessDetails(payload)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          async data => {
            if (data) {
              this.updateStatusNumberObjectForGroup(data, groupDevice);
              if (
                _.isEqual(groupDevice.statusNumberObject, groupDevice.statusNumberObjectOld) &&
                Helper.checkMappingStatusDevicesGroupAnnouncement(groupDevice, this.groupExpandedClone)
              ) {
                resolve();
                return;
              }
              groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
              await this.handleStatusForDeviceOfGroup(groupDevice);
              resolve();
            }
          },
          () => {
            this.clearIntervalForGroup(groupDevice.groupId);
            resolve();
          }
        );
    });
  }

  /**
   * Handle status for device of group
   *
   * @param groupDevice
   */
  private async handleStatusForDeviceOfGroup(groupDevice: GroupDeviceAnnouncement): Promise<void> {
    if (!groupDevice.groupJobId) {
      return;
    }
    let payLoadWaiting = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.WAITING
    };
    let payLoadInProgress = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.IN_PROGRESS
    };
    let payLoadCompleted = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.COMPLETED
    };
    let payLoadCanceled = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.CANCELLED
    };
    let payLoadFailed = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.FAILED
    };
    await new Promise<void>(resolve => {
      forkJoin({
        waitingResponse: this.callDeviceListForJobAPI(payLoadWaiting),
        inProgressResponse: this.callDeviceListForJobAPI(payLoadInProgress),
        completedResponse: this.callDeviceListForJobAPI(payLoadCompleted),
        cancelledResponse: this.callDeviceListForJobAPI(payLoadCanceled),
        failedResponse: this.callDeviceListForJobAPI(payLoadFailed)
      }).subscribe(
        async data => {
          if (data.waitingResponse.length > 0) {
            this.setStatusForDeviceCommonSpecifics(data.waitingResponse, groupDevice, DeviceStatusEnum.WAITING);
          }
          if (data.inProgressResponse.length > 0) {
            this.setStatusForDeviceCommonSpecifics(data.inProgressResponse, groupDevice, DeviceStatusEnum.IN_PROGRESS);
          }
          if (data.completedResponse.length > 0) {
            this.setStatusForDeviceCommonSpecifics(data.completedResponse, groupDevice, DeviceStatusEnum.COMPLETED);
          }
          if (data.cancelledResponse.length > 0) {
            this.setStatusForDeviceCommonSpecifics(data.cancelledResponse, groupDevice, DeviceStatusEnum.CANCELLED);
          }
          if (data.failedResponse.length > 0) {
            this.setStatusForDeviceCommonSpecifics(data.failedResponse, groupDevice, DeviceStatusEnum.FAILED);
          }
          await this.updateStatusForGroupDevices(groupDevice);
          if (groupDevice.isExpand) {
            groupDevice.deviceCommonSpecifics.forEach(device => {
              if (device.status == DeviceStatusEnum.CANCELLED || device.status == DeviceStatusEnum.FAILED) {
                device.detailStatusDisplay = null;
                if (this.groupExpandedClone) {
                  let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(data => data.registrationId == device.registrationId);
                  if (index != -1) {
                    this.groupExpandedClone.deviceCommonSpecifics[index].detailStatusDisplay = null;
                  }
                }
              }
            });
          }
          resolve();
        },
        error => {
          this.handleErrorAPIDevicesListJob(error, groupDevice.name);
          this.clearIntervalForGroup(groupDevice.groupId);
          resolve();
        }
      );
    });
  }

  /**
   * Update status for group devices
   *
   * @param groupDevice
   * @returns
   */
  private updateStatusForGroupDevices(groupDevice: GroupDeviceAnnouncement): Promise<void> {
    return new Promise<void>(resolve => {
      this.announcementDeliveryStatusService
        .saveDetailStatusForDevices(groupDevice.deviceCommonSpecifics.filter(data => data.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1))
        .subscribe(
          () => {
            resolve();
          },
          error => {
            this.handleErrorMessage(error, 'update-status-for-group-devices-failed');
            this.clearIntervalForGroup(groupDevice.groupId);
            resolve();
          }
        );
    });
  }

  /**
   * set Status For Device Calendars
   * @param registrationIds
   * @param groupDevice
   * @param status
   */
  private async setStatusForDeviceCommonSpecifics(
    registrationIds: string[],
    groupDevice: GroupDeviceAnnouncement,
    status: DeviceStatusEnum
  ): Promise<void> {
    registrationIds.forEach(async (registrationId: string) => {
      let index = groupDevice.deviceCommonSpecifics.findIndex(data => data.registrationId == registrationId);
      if (
        index != -1 &&
        groupDevice.deviceCommonSpecifics[index].jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1 &&
        groupDevice.deviceCommonSpecifics[index].status != status
      ) {
        groupDevice.deviceCommonSpecifics[index].status = status;
      }
      // set status for device of group clone
      if (this.groupExpandedClone && groupDevice.groupId == this.groupExpandedClone.groupId) {
        let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(device => device.registrationId == registrationId);
        if (index != -1) {
          this.groupExpandedClone.deviceCommonSpecifics[index].status = status;
        }
      }
    });
    // filter devices of group
    if (this.groupExpanded && this.groupExpanded.activeColumnHeader != ActiveColumnHeader.TOTAL) {
      Helper.filterStatusGroupAnnouncementBlank(this.groupExpanded, this.groupExpanded.activeColumnHeader, this.groupExpandedClone);
    }
    if (groupDevice.isExpand) {
      // show detail status if device is completing or completed
      if (status == DeviceStatusEnum.COMPLETED || status == DeviceStatusEnum.IN_PROGRESS) {
        if (this.isShowDetailStatus) {
          let devices = this.groupExpandedClone.deviceCommonSpecifics.filter(deviceCalendar =>
            registrationIds?.includes(deviceCalendar.registrationId)
          );
          let announcementDeliveryStatus = await this.updateLatestStatusForDevices(devices);
          if (announcementDeliveryStatus) {
            this.updateDetailStatusForDevices(announcementDeliveryStatus);
          }
        }
      }
    }
    Helper.updateActiveStatusNumberRealtimeAnnouncement(
      this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId ? this.groupExpandedClone : groupDevice,
      status,
      registrationIds.length
    );
  }

  /**
   * update detail status for devices
   * @param announcementDeliveryStatus
   */
  private updateDetailStatusForDevices(announcementDeliveryStatus: AnnouncementDeliveryStatus[]): void {
    this.groupDevices.forEach(groupDevice => {
      groupDevice.deviceCommonSpecifics.forEach(deviceCalendar => {
        const dataResponse = announcementDeliveryStatus.find(item => item.registrationId == deviceCalendar.registrationId);
        if (dataResponse) {
          deviceCalendar.detailStatusDisplay = this.getDetailStatusOfDevice(dataResponse, deviceCalendar.status);
        }
      });
    });
    this.groupExpandedClone?.deviceCommonSpecifics.forEach(device => {
      const deliveryStatus = announcementDeliveryStatus.find(data => device && data.registrationId == device.registrationId);
      if (deliveryStatus) {
        device.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatus, device.status);
      }
    });
  }

  /**
   * update latest status for devices delivery single
   *
   * @param devices
   * @returns
   */
  private updateLatestStatusForDevices(devices: DeviceCommonSpecific[]): Promise<AnnouncementDeliveryStatus[]> {
    return new Promise<AnnouncementDeliveryStatus[]>(resolve => {
      if (!devices.length) {
        resolve(null);
        return;
      }
      this.announcementDeliveryStatusService.updateStatusForDevices(devices.map(device => device.id)).subscribe(
        data => {
          resolve(data);
        },
        error => {
          this.handleErrorMessage(error, 'update-latest-status-for-devices-failed');
          resolve(null);
        }
      );
    });
  }

  /**
   * Call device list for job API
   *
   * @param payload
   * @returns
   */
  private callDeviceListForJobAPI(payload: any): Promise<string[]> {
    return new Promise(resolve => {
      this.apiCustomerService.deviceListForJob(payload).subscribe(
        async data => {
          resolve(data[this.DEVICE_LIST_ELEMENT]);
        },
        () => {
          resolve([]);
        }
      );
    });
  }

  /**
   * update status number object for group
   *
   * @param data
   * @param groupDevice
   * @returns
   */
  private updateStatusNumberObjectForGroup(data: any, groupDevice: GroupDeviceAnnouncement): void {
    groupDevice.statusNumberObject.waitingNumber = data[this.WAITING_ELEMENT];
    groupDevice.statusNumberObject.inprogressNumber = data[this.IN_PROGRESS_ELEMENT];
    groupDevice.statusNumberObject.completedNumber = data[this.SUCCESSFUL_ELEMENT];
    groupDevice.statusNumberObject.cancelNumber = data[this.CANCEL_ELEMENT];
    groupDevice.statusNumberObject.failedNumber = data[this.FAILED_ELEMENT];
  }

  /**
   * Clear interval for group
   *
   * @param groupId
   */
  private clearIntervalForGroup(groupId: string): void {
    const index = this.intervalUpdateStatusArray.findIndex(data => data.groupId == groupId);
    if (index == -1) {
      return;
    }
    this.intervalUpdateStatusArray[index].interval.unsubscribe();
    this.intervalUpdateStatusArray.splice(index, 1);
    this.clearIntervalForGroup(groupId);
  }

  /**
   * check status call job detail
   * @param groupDevice
   * @returns
   */
  private checkStatusCallJobDetail(groupDevice: GroupDeviceAnnouncement): boolean {
    let groupDeviceCheck = groupDevice;
    if (this.groupExpandedClone && groupDevice.groupId == this.groupExpandedClone.groupId) {
      groupDeviceCheck = _.cloneDeep(this.groupExpandedClone);
    }
    // no devices waiting or in progress | no groupJobId | all device of group is delivery single | (waitingNumber == 0 && inprogressNumber == 0)
    return (
      !groupDeviceCheck.deviceCommonSpecifics
        .filter(data => data.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
        .some(device => device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) ||
      groupDeviceCheck.deviceCommonSpecifics.every(device => device.jobId && device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1) ||
      !groupDeviceCheck.groupJobId
    );
  }

  /**
   * Handle update status for devices interval
   *
   * @param group
   * @returns
   */
  private handleUpdateStatusForDevicesInterval(): any {
    return interval(1000)
      .pipe(
        concatMap(() => {
          return this.updateStatusForDevicesInterval();
        })
      )
      .subscribe();
  }

  /**
   * update status for devices interval
   */
  private updateStatusForDevicesInterval(): Promise<void> {
    const deviceIds = Helper.getSingleDeviceAnnouncementIdsNotYetCompleted(this.groupDevices, this.groupExpandedClone);
    if (!deviceIds || deviceIds.length < 1) {
      this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.announcementDeliveryStatusService
        .updateStatusForDevices(deviceIds)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          deliveryStatusTimes => {
            deliveryStatusTimes.forEach(deliveryStatus => {
              let device = this.deviceCommonSpecifics.find(item => item.id == deliveryStatus.deviceId);
              if (!device) {
                return;
              }
              device.status = deliveryStatus.status;
              this.groupDevices.forEach(group => {
                group.deviceCommonSpecifics.forEach(deviceCalendar => {
                  if (deviceCalendar.registrationId == deliveryStatus.registrationId) {
                    deviceCalendar.status = device.status;
                    deviceCalendar.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatus, deviceCalendar.status);
                  }
                });
              });

              if (this.groupExpandedClone) {
                let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(data => data.registrationId == device.registrationId);
                if (index != -1) {
                  this.groupExpandedClone.deviceCommonSpecifics[index].status = device.status;
                  this.groupExpandedClone.deviceCommonSpecifics[index].detailStatusDisplay = this.getDetailStatusOfDevice(
                    deliveryStatus,
                    this.groupExpandedClone.deviceCommonSpecifics[index].status
                  );
                }
              }
            });
            resolve();
          },
          error => {
            reject();
            this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
            this.handleErrorMessage(error, 'get-status-for-single-device-failed');
          }
        );
    });
  }
  /**
   * check or uncheck all device
   */
  public checkAll(): void {
    this.isCheckingAll = !this.isCheckingAll;
    this.groupDevices.forEach(group => {
      group.isChecking = this.isCheckingAll;
      group.deviceCommonSpecifics?.forEach(device => {
        device.isChecking = this.isCheckingAll;
        this.deviceCommonSpecifics.forEach(data => {
          if (data.registrationId == device.registrationId) {
            data.isChecking = device.isChecking;
          }
        });
      });
      if (this.groupExpandedClone?.groupId == group.groupId && group.groupId) {
        this.groupExpandedClone.isChecking = group.isChecking;
        this.groupExpandedClone.deviceCommonSpecifics.forEach(device => (device.isChecking = group.isChecking));
      }
    });
  }

  /**
   * check or uncheck a group
   * @param index index of check-changed device
   * @param e
   */
  public changeCheckedGroup(index: number, e: any): void {
    e.stopPropagation();
    let groupDevice = this.groupDevices[index];
    groupDevice.isChecking = !groupDevice.isChecking;
    groupDevice.deviceCommonSpecifics.forEach(device => {
      device.isChecking = groupDevice.isChecking;
      this.deviceCommonSpecifics.forEach(data => {
        if (data.registrationId == device.registrationId) {
          data.isChecking = device.isChecking;
        }
      });
    });
    this.isCheckingAll = this.groupDevices?.every(group => group.isChecking);
    if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId) {
      this.groupExpandedClone.isChecking = groupDevice.isChecking;
      this.groupExpandedClone.deviceCommonSpecifics.forEach(device => (device.isChecking = groupDevice.isChecking));
    }
  }

  /**
   * open group
   * @param groupDevice
   */
  public openGroup(groupDevice: GroupDeviceAnnouncement): void {
    groupDevice.activeColumnHeader = ActiveColumnHeader.TOTAL;
    this.isShowDetailStatus = false;
    groupDevice.isChecking =
      groupDevice.deviceCommonSpecifics.every(device => device.isChecking) && groupDevice.deviceCommonSpecifics.length > 0;
    this.checkGroupExistsDeviceDeliveryGroup();
    this.groupDevices.forEach(group => {
      group.name == groupDevice.name ? (group.isExpand = !group.isExpand) : (group.isExpand = false);
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId) {
        group.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics;
        group.activeColumnHeader = ActiveColumnHeader.TOTAL;
      }
      group.deviceCommonSpecifics.forEach(device => {
        device.detailStatusDisplay = null;
      });
    });
    this.groupExpanded = groupDevice.isExpand ? groupDevice : undefined;
    this.groupExpandedClone = _.cloneDeep(this.groupExpanded);
  }

  /**
   * check group exists device delivery group
   */
  private checkGroupExistsDeviceDeliveryGroup(): void {
    this.isDeliveryGroup = !this.groupExpandedClone
      ? false
      : this.groupExpandedClone.deviceCommonSpecifics.some(
          device =>
            (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) &&
            device.jobId &&
            device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1
        ) ||
        this.groupExpandedClone.deviceCommonSpecifics.every(
          device =>
            device.isDelivering && (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1 || device.jobId == this.groupExpandedClone.name)
        );
  }

  /**
   * Filter status device
   *
   * @param group
   * @param activeColumn
   * @returns
   */
  public async filterStatusDevice(group: GroupDeviceAnnouncement, activeColumn: ActiveColumnHeader) {
    if (group.isExpand || !group.name) {
      return;
    }

    // openGroup
    this.isShowDetailStatus = false;
    group.isChecking = group.deviceCommonSpecifics.every(device => device.isChecking) && group.deviceCommonSpecifics.length > 0;
    this.groupDevices.forEach(groupDevice => {
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId) {
        groupDevice.isExpand = false;
        groupDevice.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics;
        groupDevice.activeColumnHeader = ActiveColumnHeader.TOTAL;
      }
      groupDevice.deviceCommonSpecifics.forEach(device => {
        device.detailStatusDisplay = null;
      });
    });
    this.groupExpanded = group;
    this.groupExpandedClone = _.cloneDeep(this.groupExpanded);
    this.checkGroupExistsDeviceDeliveryGroup();

    // filter device of group
    group.activeColumnHeader = activeColumn;
    // Case delivering data
    if (this.isDeliveryGroup && activeColumn == ActiveColumnHeader.WAITING) {
      group.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics.filter(data => data.status == DeviceStatusEnum.WAITING);
      group.isChecking = group.deviceCommonSpecifics.every(device => device.isChecking) && group.deviceCommonSpecifics.length > 0;
      this.setIsExpandedForGroup(group);
      return;
    }

    // Case false
    if (
      activeColumn == ActiveColumnHeader.FAILED &&
      this.groupExpandedClone.deviceCommonSpecifics.every(device => device.jobId == this.groupExpandedClone.name)
    ) {
      this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
      this.clearAllIntervalDeliveryGroup();
      group.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics.filter(data => data.status == DeviceStatusEnum.FAILED);
      group.isChecking = group.deviceCommonSpecifics.every(device => device.isChecking) && group.deviceCommonSpecifics.length > 0;
      this.setIsExpandedForGroup(group);
      return;
    }

    if (group.groupId) {
      this.executingService.executing();
      await this.filterStatusGroup(group, activeColumn);
      this.executingService.executed();
    }
    group.isChecking = group.deviceCommonSpecifics.every(device => device.isChecking) && group.deviceCommonSpecifics.length > 0;
    this.setIsExpandedForGroup(group);
  }

  /**
   * set is expanded for group
   * @param group
   */
  private setIsExpandedForGroup(group: GroupDeviceAnnouncement): void {
    this.groupDevices.forEach(groupDevice => {
      groupDevice.name == group.name ? (groupDevice.isExpand = !groupDevice.isExpand) : (groupDevice.isExpand = false);
    });
  }

  /**
   * clear interval
   */
  private clearIntervalForComponent(interval: any) {
    if (interval) {
      interval.unsubscribe();
    }
  }

  /**
   * Clear all interval delivery
   *
   * @returns
   */
  private clearAllIntervalDeliveryGroup(): void {
    this.intervalUpdateStatusArray.forEach(data => data.interval.unsubscribe());
    this.intervalUpdateStatusArray = new Array<DeliveryGroupIntervalObject>();
    this.cancelHttpRequest();
  }

  /**
   * filter status of group
   * @param group
   * @param activeColumn
   */
  private async filterStatusGroup(group: GroupDeviceAnnouncement, activeColumn: ActiveColumnHeader): Promise<void> {
    if (!group.groupJobId) {
      group.deviceCommonSpecifics = [];
      return;
    }
    let payload = {
      jobId: group.groupJobId
    };
    let status = null;
    switch (activeColumn) {
      case ActiveColumnHeader.WAITING:
        payload[this.STATUS_ELEMENT] = PayloadDeviceStatusEnum.WAITING;
        status = DeviceStatusEnum.WAITING;
        break;
      case ActiveColumnHeader.IN_PROGRESS:
        payload[this.STATUS_ELEMENT] = PayloadDeviceStatusEnum.IN_PROGRESS;
        status = DeviceStatusEnum.IN_PROGRESS;
        break;
      case ActiveColumnHeader.COMPLETED:
        payload[this.STATUS_ELEMENT] = PayloadDeviceStatusEnum.COMPLETED;
        status = DeviceStatusEnum.COMPLETED;
        break;
      case ActiveColumnHeader.CANCEL:
        payload[this.STATUS_ELEMENT] = PayloadDeviceStatusEnum.CANCELLED;
        status = DeviceStatusEnum.CANCELLED;
        break;
      case ActiveColumnHeader.FAILED:
        payload[this.STATUS_ELEMENT] = PayloadDeviceStatusEnum.FAILED;
        status = DeviceStatusEnum.FAILED;
        break;
      default:
        break;
    }
    if (!status) {
      group.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics;
    } else {
      let response = await this.callAPIDeviceListForJob(payload, group.name);
      if (!response || !response.length) {
        group.deviceCommonSpecifics = [];
        return;
      }
      response.forEach((registrationId: string) => {
        let index = group.deviceCommonSpecifics.findIndex(data => data.registrationId == registrationId);
        if (index != -1 && group.deviceCommonSpecifics[index].jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
          group.deviceCommonSpecifics[index].status = status;
          if (status == DeviceStatusEnum.CANCELLED) {
            group.deviceCommonSpecifics[index].detailStatusDisplay = null;
          }
        }
        if (this.groupExpandedClone && group.groupId == this.groupExpandedClone.groupId) {
          let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(device => device.registrationId == registrationId);
          if (index != -1) {
            this.groupExpandedClone.deviceCommonSpecifics[index].status = status;
            if (status == DeviceStatusEnum.CANCELLED) {
              this.groupExpandedClone.deviceCommonSpecifics[index].detailStatusDisplay = null;
            }
          }
        }
      });
      group.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics.filter(device => device.status == status);
      if (group.isChecking) {
        group.deviceCommonSpecifics.forEach(device => (device.isChecking = group.isChecking));
      }
    }
  }

  /**
   * call API device list for job
   *
   * @param payload
   * @param groupName
   * @returns
   */
  private callAPIDeviceListForJob(payload: any, groupName: string): Promise<string[]> {
    return new Promise<string[]>(resolve => {
      this.apiCustomerService.deviceListForJob(payload).subscribe(
        data => {
          resolve(data[this.DEVICE_LIST_ELEMENT]);
        },
        error => {
          this.handleErrorAPIDevicesListJob(error, groupName);
          resolve(null);
        }
      );
    });
  }

  /**
   * Handle error devices list job
   *
   * @param error
   * @param value
   */
  private handleErrorAPIDevicesListJob(error: any, value: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network')
            : Helper.formatString(this.translateService.instant(`dialog-delivery-announcement.get-device-list-for-job-failed`), value)
      }
    });
  }

  /**
   * get detail status
   */
  public getDetailStatus(): void {
    if (this.isShowDetailStatus || !this.groupExpanded || !this.groupExpanded.deviceCommonSpecifics?.length) {
      return;
    }
    this.isShowDetailStatus = true;
    this.executingService.executing();
    this.groupExpanded.deviceCommonSpecifics.forEach(device => (device.detailStatusDisplay = this.getDetailStatusByStatusOfDevice(device)));
    this.groupExpandedClone.deviceCommonSpecifics.forEach(
      device => (device.detailStatusDisplay = this.getDetailStatusByStatusOfDevice(device))
    );
    this.announcementDeliveryStatusService
      .updateStatusForDevices(this.groupExpandedClone.deviceCommonSpecifics?.map(device => device.id))
      .subscribe(
        data => {
          if (data) {
            this.groupExpandedClone.deviceCommonSpecifics.forEach(device => {
              const dataResponse = data.find(item => item.registrationId == device.registrationId);
              if (dataResponse) {
                device.detailStatusDisplay = this.getDetailStatusOfDevice(dataResponse, device.status);
                let indexDevice = this.groupExpanded.deviceCommonSpecifics.findIndex(
                  deviceCalendar => deviceCalendar.registrationId == device.registrationId
                );
                if (indexDevice != -1) {
                  this.groupExpanded.deviceCommonSpecifics[indexDevice].detailStatusDisplay = device.detailStatusDisplay;
                }
              }
            });
          }

          this.executingService.executed();
        },
        error => {
          this.handleErrorMessage(error, 'get-detailed-status-of-device-failed');
          this.executingService.executed();
        }
      );
  }

  /**
   * handle error message
   * @param error
   * @param message
   */
  private handleErrorMessage(error: any, message: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : this.translateService.instant(`dialog-delivery-announcement.${message}`)
      }
    });
  }

  /**
   * check or uncheck a device of group
   * @param device
   * @param e
   * @param group
   */
  public changeCheckedDeviceGroup(device: DeviceCommonSpecific, e: any, group: GroupDeviceAnnouncement): void {
    e.stopPropagation();
    device.isChecking = !device.isChecking;
    group.isChecking = group.deviceCommonSpecifics.every(device => device.isChecking);
    this.deviceCommonSpecifics.forEach(data => {
      if (data.registrationId == device.registrationId) {
        data.isChecking = device.isChecking;
      }
    });
    this.isCheckingAll = this.groupDevices?.every(group => group.isChecking);
    if (this.groupExpandedClone?.groupId == group.groupId) {
      this.groupExpandedClone.isChecking = group.isChecking;
      let index = this.groupExpandedClone.deviceCommonSpecifics.findIndex(data => data.registrationId == device.registrationId);
      if (index != -1) {
        this.groupExpandedClone.deviceCommonSpecifics[index].isChecking = device.isChecking;
      }
    }
  }

  /**
   * get detail status of device
   * @param announcementDeliveryStatus
   * @param status
   * @returns
   */
  private getDetailStatusOfDevice(announcementDeliveryStatus: AnnouncementDeliveryStatus, status: string): any {
    switch (status) {
      case DeviceStatusEnum.FAILED:
      case DeviceStatusEnum.CANCELLED:
        return null;
      case DeviceStatusEnum.COMPLETED:
      case DeviceStatusEnum.IN_PROGRESS:
        return announcementDeliveryStatus.detailStatus
          ? Helper.formatString(
              status == DeviceStatusEnum.IN_PROGRESS
                ? this.translateService.instant(`dialog-delivery-announcement.detail-status.delivery-completing`)
                : this.translateService.instant(`dialog-delivery-announcement.detail-status.delivery-completed`),
              announcementDeliveryStatus.downloadedFiles,
              announcementDeliveryStatus.totalFile
            )
          : null;
      case DeviceStatusEnum.WAITING:
        return this.translateService.instant(`dialog-delivery-announcement.detail-status.${DeviceDetailStatusEnum.PREPARING_DATA}`);
      default:
        return null;
    }
  }

  /**
   * get detail status by status of device
   * @param device
   * @returns
   */
  private getDetailStatusByStatusOfDevice(device: DeviceCommonSpecific): any {
    if (device?.status == DeviceStatusEnum.WAITING) {
      return this.translateService.instant(`dialog-delivery-timetable.detail-status.${DeviceDetailStatusEnum.PREPARING_DATA}`);
    }
    return null;
  }

  /**
   * cancel http request
   */
  private cancelHttpRequest(): void {
    this.subject$.next();
  }

  /**
   * Get devices checked
   *
   * @returns
   */
  private getDevicesChecked(): Array<DeviceCommonSpecific> {
    let checkedDevices = [];
    this.groupDevices.forEach(group => {
      let devices = group.deviceCommonSpecifics.filter(data => data.isChecking);
      if (devices.length > 0) {
        checkedDevices = _.concat(checkedDevices, devices);
      }
    });
    return checkedDevices;
  }

  /**
   * Handle device detail status
   *
   * @param devicesIdChecked
   * @param deviceStatus
   * @param detailStatus
   */
  private handleDeviceDetailStatus(devicesIdChecked: Array<Number>, deviceStatus: string, detailStatus: string): void {
    devicesIdChecked.forEach(deviceId => {
      this.handleStatusForDevice(deviceId, deviceStatus, detailStatus);
    });
  }

  /**
   * handle status for device
   * @param deviceId
   * @param deviceStatus
   * @param detailStatus
   */
  private handleStatusForDevice(deviceId: any, deviceStatus: string, detailStatus: string): void {
    let status = detailStatus ? this.translateService.instant(`dialog-delivery-announcement.detail-status.${detailStatus}`) : null;
    let index = this.deviceCommonSpecifics.findIndex(deviceCalendar => deviceCalendar.id == deviceId);
    if (index != -1) {
      this.deviceCommonSpecifics[index].status = deviceStatus;
      this.deviceCommonSpecifics[index].detailStatusDisplay = status;
    }
    if (this.groupExpandedClone) {
      let indexOfGroup = this.groupExpandedClone.deviceCommonSpecifics?.findIndex(deviceCalendar => deviceCalendar.id == deviceId);
      if (indexOfGroup != -1) {
        this.groupExpandedClone.deviceCommonSpecifics[indexOfGroup].detailStatusDisplay = status;
        this.groupExpandedClone.deviceCommonSpecifics[indexOfGroup].status = deviceStatus;
      }
    }
    this.groupDevices.forEach(group => {
      group.deviceCommonSpecifics.forEach(device => {
        if (device.id == deviceId) {
          device.detailStatusDisplay = status;
          device.status = deviceStatus;
        }
      });
    });
  }

  /**
   * update status number for groups
   * @param checkedDevices
   * @param isGroupChecked
   * @param isChangeNumber
   */
  private updateStatusNumberForGroups(checkedDevices: DeviceCommonSpecific[], isGroupChecked: boolean, isChangeNumber?: boolean): void {
    const groupIdSet = new Set(checkedDevices.map(device => device.groupId));
    this.groupDevices.forEach(group => {
      if ([...groupIdSet].includes(group.groupId)) {
        group.statusNumberObject.waitingNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.WAITING, group, isGroupChecked);
        group.statusNumberObject.failedNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.FAILED, group, isGroupChecked);
        if (isChangeNumber && group.isChecking) {
          group.statusNumberObject.inprogressNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.IN_PROGRESS, group, isGroupChecked);
          group.statusNumberObject.completedNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.COMPLETED, group, isGroupChecked);
          group.statusNumberObject.cancelNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.CANCELLED, group, isGroupChecked);
        }
        if (this.groupExpandedClone && this.groupExpandedClone.groupJobId == group.groupJobId) {
          this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
        }
      }
    });
  }

  /**
   * delivery
   * @returns
   */
  public delivery(): void {
    let checkedDevices = this.getDevicesChecked();
    // no device selected
    if (!checkedDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-delivery-announcement.choose-device')
        }
      });
      return;
    }
    if (checkedDevices.some(device => device.status === DeviceStatusEnum.WAITING || device.status === DeviceStatusEnum.IN_PROGRESS)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-delivery-announcement.delivery-process')
        }
      });
      return;
    }
    const deviceIds = checkedDevices?.map(device => device.id);
    this.handleDeviceDetailStatus(deviceIds, DeviceStatusEnum.WAITING, DeviceDetailStatusEnum.CREATE_DATA);
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
    this.updateStatusNumberForGroups(checkedDevices, true, true);

    if (this.groupExpandedClone) {
      this.isDeliveryGroup = !this.groupExpandedClone.deviceCommonSpecifics.some(deviceCalendar => !deviceCalendar.isChecking);
    }
    this.announcementDeliveryStatusService.checkExistPlaylistOfCommonRegistration(deviceIds).subscribe(
      async isExistPlaylist => {
        if (isExistPlaylist) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant('dialog-delivery-announcement.no-set-playlist'),
                button1: this.translateService.instant('dialog-delivery-announcement.yes'),
                button2: this.translateService.instant('dialog-delivery-announcement.no')
              }
            },
            async result => {
              if (result) {
                this.handleCheckSequenceAndTotalSizeForDelivery(deviceIds, checkedDevices);
              } else {
                await this.saveDevicesCheckedWhenStatusFailed(checkedDevices, true);
                return;
              }
            }
          );
        } else {
          this.handleCheckSequenceAndTotalSizeForDelivery(deviceIds, checkedDevices);
        }
      },
      async () => {
        await this.saveDevicesCheckedWhenStatusFailed(checkedDevices);
      }
    );
  }

  /**
   * handle check sequence and total size
   * @param deviceIds
   * @param checkedDevices
   */
  public handleCheckSequenceAndTotalSizeForDelivery(deviceIds: number[], checkedDevices: DeviceCommonSpecific[]): void {
    // if exist playlist no data sequence -> show dialog error
    this.announcementDeliveryStatusService.checkExistDataSequence(deviceIds).subscribe(
      async isExist => {
        if (isExist) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-delivery-announcement.no-sequence')
            }
          });
          await this.saveDevicesCheckedWhenStatusFailed(checkedDevices, true);
          return;
        } else {
          // if total size > 1GB -> show dialog error
          // validate total size of devices
          this.announcementDeliveryStatusService.validateTotalSizeOfDevices(deviceIds).subscribe(
            async datas => {
              if (datas?.length) {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    texts: [this.translateService.instant('dialog-delivery-announcement.maximum-file-size-delivery'), `${datas}`]
                  }
                });
                await this.saveDevicesCheckedWhenStatusFailed(checkedDevices, true);
                return;
              } else {
                this.deliveryUpload(checkedDevices);
              }
            },
            async () => {
              await this.saveDevicesCheckedWhenStatusFailed(checkedDevices);
            }
          );
        }
      },
      async () => {
        await this.saveDevicesCheckedWhenStatusFailed(checkedDevices);
      }
    );
  }

  /**
   * handle Error Before Upload
   * @param checkedDevices
   * @returns
   */
  private handleErrorBeforeUpload(checkedDevices: DeviceCommonSpecific[]) {
    return new Promise<void>(resolve => {
      this.announcementDeliveryStatusService.getStatusDeviceTimetableEditor(checkedDevices).subscribe(
        deviceResult => {
          const deviceIdsObject = Helper.getDevicesDeliveryObjectIdAnnouncement(checkedDevices, this.groupDevices, this.groupExpandedClone);
          let deviceFaileds = [];
          let errorMessages = new Array<string>();
          //device deliverySingle
          const deliverySingle = [...deviceResult].filter(device => [...deviceIdsObject['singleIds']].includes(device.id));
          if (deliverySingle.length > 0) {
            deliverySingle.forEach(device => {
              if (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) {
                let index = checkedDevices.findIndex(deviceChecked => deviceChecked.id == device.id);
                if (index > -1) {
                  checkedDevices[index].status = DeviceStatusEnum.FAILED;
                  checkedDevices[index].jobId = null;
                  if (this.groupExpandedClone) {
                    let indexClone = this.groupExpandedClone.deviceCommonSpecifics.findIndex(deviceClone => deviceClone.id == device.id);
                    if (indexClone) {
                      this.groupExpandedClone.deviceCommonSpecifics[indexClone].status = DeviceStatusEnum.FAILED;
                      this.groupExpandedClone.deviceCommonSpecifics[indexClone].jobId = null;
                    }
                  }
                  errorMessages.push(
                    Helper.formatString(
                      this.translateService.instant('dialog-playlist-recurrence.device-not-yet-completed'),
                      checkedDevices[index].name
                    )
                  );
                  deviceFaileds.push(checkedDevices.splice(index, 1)[0]);
                }
              }
            });
          }
          //device deliveryGroup
          const deliveryGroup = [...deviceResult].filter(device => [...deviceIdsObject['groupIds']].includes(device.id));
          if (deliveryGroup.length > 0) {
            let groupIds = [
              ...new Set(
                deliveryGroup
                  .filter(element => element.status == DeviceStatusEnum.WAITING || element.status == DeviceStatusEnum.IN_PROGRESS)
                  .map(data => data.groupId)
              )
            ];
            groupIds.forEach(groupId => {
              this.groupDevices.forEach(group => {
                // clone
                if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupId) {
                  this.groupExpandedClone.groupId = this.groupExpandedClone.name;
                  this.groupExpandedClone.deviceCommonSpecifics.forEach(device => {
                    device.status = DeviceStatusEnum.FAILED;
                    device.jobId = this.groupExpandedClone.groupId;
                  });
                }
                // groups
                if (group.groupId == groupId) {
                  group.groupJobId = group.name;
                  group.deviceCommonSpecifics.forEach(device => {
                    device.status = DeviceStatusEnum.FAILED;
                    device.jobId = group.groupJobId;
                    deviceFaileds.push(device);
                  });
                  errorMessages.push(
                    Helper.formatString(this.translateService.instant('dialog-playlist-recurrence.group-not-yet-completed'), group.name)
                  );
                }
                _.remove(checkedDevices, function(deviceRemove) {
                  return deviceRemove.groupId == groupId;
                });
              });
            });
          }
          if (deviceFaileds.length > 0) {
            this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                texts: errorMessages
              }
            });
            this.saveDevicesCheckedWhenStatusFailed(deviceFaileds, true, true);
          }
          resolve();
        },
        error => {
          this.handleErrorMessage(error, 'get-status-for-device-in-timetable-failed');
          resolve();
        }
      );
    });
  }

  /**
   * Publish data simple signage
   *
   * @param publishDeviceIdGroups
   * @param dataSetting
   * @param checkedDevices
   * @param groupNamesChecked
   */
  private async publishDataAnnouncement(
    publishDeviceIdGroups: Array<PublishDeviceIdGroup>,
    dataSetting: PublishSetting,
    checkedDevices: DeviceCommonSpecific[],
    groupNamesChecked: string[]
  ): Promise<Promise<DeviceDeliveryObject | DeviceDeliveryObject[] | undefined>[]> {
    checkedDevices.forEach(device => {
      device.isDelivering = true;
      Helper.setDataForDeviceGroupCloneAnnouncement(Constant.IS_DELIVERY_ELEMENT, device.isDelivering, device.id, this.groupExpandedClone);
    });
    return dataSetting.devicesId.map(deviceId => {
      if (this.isDeliveryCancelled(deviceId.valueOf())) {
        return;
      }

      dataSetting.deviceId = deviceId;
      let ids = publishDeviceIdGroups?.find(device => device.firstId == deviceId)?.ids;
      ids?.push(deviceId);
      return new Promise<DeviceDeliveryObject | Array<DeviceDeliveryObject> | undefined>(resolve => {
        this.http
          .post(Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL + 'api/publish/announcement', dataSetting, {
            reportProgress: true,
            observe: 'events'
          })
          .subscribe(
            async (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request has been made!');
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  break;
                case HttpEventType.Response:
                  console.log('Request succeeded!');
                  if (this.isDeliveryCancelled(deviceId.valueOf())) {
                    resolve(undefined);
                    return;
                  }

                  let responsePublish = event.body;
                  // get information publish file name for device
                  let responsesPublish = ids?.map(id => new ResponsePublish(id, responsePublish.publishFileName));

                  this.handleDeviceDetailStatus(ids, DeviceStatusEnum.WAITING, DeviceDetailStatusEnum.PREPARING_DATA);
                  let data: Array<DeviceDeliveryObject> = await this.deliveryDataAnnouncement(responsesPublish, groupNamesChecked);
                  await Promise.all([...data]).then(async (deviceDeliveryObjects: Array<DeviceDeliveryObject>) => {
                    resolve(deviceDeliveryObjects);
                  });
              }
            },
            async () => {
              this.handleDeviceDetailStatus(ids, DeviceStatusEnum.FAILED, null);
              resolve(new DeviceDeliveryObject(deviceId, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR, null, null));
            }
          );
      });
    });
  }

  /**
   * delivery data simple
   * @param responsesPublish
   * @param groupNamesChecked
   * @returns
   */
  private async deliveryDataAnnouncement(responsesPublish: ResponsePublish[], groupNamesChecked: string[]): Promise<any> {
    return responsesPublish.map(responsePublish => {
      let deviceDeliveryObject = new DeviceDeliveryObject(
        responsePublish.deviceId,
        null,
        this.getGroupIdWhenDelivery(responsePublish.deviceId, groupNamesChecked),
        null
      );
      return new Promise<DeviceDeliveryObject>(resolve => {
        this.devicePublishInfoService.deliveryForAnnouncement(responsePublish).subscribe(
          data => {
            let deviceInfo = data['response']?.split(':');
            if (deviceInfo[1] == DeviceDetailStatusEnum.DELIVERY_DATA_ERROR) {
              deviceDeliveryObject.error = deviceInfo[1];
              resolve(deviceDeliveryObject);
            } else {
              resolve(deviceDeliveryObject);
            }
          },
          () => {
            deviceDeliveryObject.error = DeviceDetailStatusEnum.PREPARING_DATA_ERROR;
            resolve(deviceDeliveryObject);
          }
        );
      });
    });
  }

  /**
   * Get group id when delivery
   *
   * @param deviceId
   * @param groupNamesChecked
   * @returns
   */
  private getGroupIdWhenDelivery(deviceId: any, groupNamesChecked: string[]): string {
    for (let index = 0; index < this.groupDevices.length; index++) {
      let group =
        this.groupExpandedClone && this.groupExpandedClone.groupId == this.groupDevices[index].groupId
          ? this.groupExpandedClone
          : this.groupDevices[index];
      if (
        group.deviceCommonSpecifics.every(data => data.isDelivering) &&
        group.deviceCommonSpecifics.find(device => device.id == deviceId) &&
        groupNamesChecked?.includes(group.name)
      ) {
        return group.groupId;
      }
    }
    return null;
  }

  /**
   * get publish device id groups
   * @param deviceContentDays
   * @param checkedDevices
   * @returns
   */
  private getPublishDeviceIdGroups(checkedDevices: DeviceCommonSpecific[]): Array<PublishDeviceIdGroup> {
    let distinctDeviceInfoJson = [];
    let publishDeviceIdGroups = new Array<PublishDeviceIdGroup>();
    // compare data
    checkedDevices.forEach(device => {
      const deviceInfoJson = JSON.stringify({
        commonAnnouncement1: device.commonAnnouncement1,
        commonAnnouncement2: device.commonAnnouncement2,
        periodicAnnouncement: device.periodicAnnouncement,
        playlistSpecific: device.playlistSpecific
      });
      const index = distinctDeviceInfoJson.findIndex(data => data == deviceInfoJson);
      if (index == -1) {
        distinctDeviceInfoJson.push(deviceInfoJson);
        publishDeviceIdGroups.push(new PublishDeviceIdGroup(device.id, []));
      } else {
        publishDeviceIdGroups[index].ids.push(device.id);
      }
    });
    return publishDeviceIdGroups;
  }

  /**
   * delivery cancellation check
   */
  private isDeliveryCancelled(deviceId: number): boolean {
    return this.cancelledDeviceIds.includes(deviceId);
  }

  /**
   * delivery upload
   *
   * @param checkedDevices
   */
  private async deliveryUpload(checkedDevices: Array<DeviceCommonSpecific>): Promise<void> {
    this.cancelledDeviceIds = [];
    this.groupBreakdownNames = [];

    await this.handleErrorBeforeUpload(checkedDevices);
    if (!checkedDevices.length) {
      return;
    }
    let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
    checkedDevices.forEach(device => {
      device.jobId = this.getCMPJobIDWhenDelivery(device);
    });
    if (this.groupExpandedClone) {
      this.groupExpandedClone.deviceCommonSpecifics.forEach(device => {
        let index = checkedDevices.findIndex(item => item.id == device.id && item.jobId == this.groupExpandedClone.name);
        if (index != -1) {
          device.jobId = this.groupExpandedClone.name;
        }
      });
    }
    this.announcementDeliveryStatusService.saveDetailStatusForDevices(checkedDevices).toPromise();
    dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);

    let groupNamesChecked = _.cloneDeep(this.groupDevices)
      .filter(group => group.isChecking)
      ?.map(item => item.name);
    this.handleCheckedAfterCancelDelivery();
    // convert data
    const publishDeviceIdGroups = this.getPublishDeviceIdGroups(checkedDevices);
    // get list device id
    dataSetting.devicesId = publishDeviceIdGroups.map(item => item.firstId);
    let promises = await this.publishDataAnnouncement(publishDeviceIdGroups, dataSetting, checkedDevices, groupNamesChecked);
    await Promise.all([...promises]).then(async _deviceDeliveryObjects => {
      const deviceDeliveryObjects = _deviceDeliveryObjects.filter(device => device != undefined);

      let dataPublish: Array<DeviceDeliveryObject> = [];
      deviceDeliveryObjects.forEach(item => {
        if (Array.isArray(item)) {
          dataPublish = dataPublish.concat(item);
        } else {
          dataPublish.push(item);
        }
      });
      let deviceDeliveryFailed = dataPublish.filter(device => device.error);
      let errorMessages = new Array<String>();
      if (deviceDeliveryFailed.length > 0) {
        let errors = deviceDeliveryFailed.map(device => {
          return `${this.deviceCommonSpecifics.find(data => data.id == device.deviceId).name}: ${this.translateService.instant(
            'dialog-delivery-announcement.delivery-failed'
          )}`;
        });

        if (errors.length > 0) {
          errorMessages = _.concat(errorMessages, errors);
        }
      }
      let deviceDeliverySuccess = dataPublish.filter(device => !device.error && !this.isDeliveryCancelled(device.deviceId.valueOf()));
      // handle call API for single device
      let deviceDeliverySingles = deviceDeliverySuccess
        // allow 1) non-group delivery 2) was a group delivery but user cancelled some devices inside it
        .filter(device => !device.groupId || (device.groupId && this.groupBreakdownNames.includes(device.groupId)))
        .map(data => {
          return data.deviceId;
        });
      // handle call API for group
      let deviceDeliveryGroups = new Array<DeliveryGroupDevice>();
      this.groupDevices.forEach(group => {
        if (
          // allow 1) user initiated group delivery 2) user hasn't cancelled any device inside the group delivery
          this.isGroupChecked(group, _.uniq(deviceDeliverySuccess.map(data => data.groupId))) &&
          groupNamesChecked?.includes(group.name) &&
          !this.groupBreakdownNames.includes(group.groupId)
        ) {
          let deviceDeliveryGroup = new DeliveryGroupDevice(
            group.groupId,
            deviceDeliverySuccess
              .filter(device => device.groupId == group.groupId)
              .map(data => {
                return data.deviceId;
              })
          );
          deviceDeliveryGroups.push(deviceDeliveryGroup);
        }
      });
      if (deviceDeliveryGroups.length > 0) {
        for (let i = 0; i < deviceDeliveryGroups.length; i++) {
          let deviceDeliveryGroup = deviceDeliveryGroups[i];
          let payload = {
            groupId: deviceDeliveryGroup.groupId
          };

          // let response = await this.callAPIForGroupDevices(payload, deviceDeliveryGroup);
          let response = await this.callAPIForGroupDevices(deviceDeliveryGroup);
          if (response == this.DELIVERY_FAILED || !response[0].includes(Constant.DELIVERY_GROUP_KEY)) {
            let errors = deviceDeliveryGroup.deviceIds.map(deviceId => {
              return `${this.deviceCommonSpecifics.find(data => data.id == deviceId).name}: ${this.translateService.instant(
                'dialog-delivery-announcement.delivery-failed'
              )}`;
            });
            if (errors.length > 0) {
              errorMessages = _.concat(errorMessages, errors);
            }
          } else {
            this.groupDevices.forEach(group => {
              if (group.groupId == deviceDeliveryGroup.groupId) {
                let jobIdResponse = response[0].split(this.JOB_ID_SPLIT_KEY);
                let jobId = jobIdResponse[1];

                group.statusNumberObject.waitingNumber = group.deviceCommonSpecifics?.length;
                group.statusNumberObject.completedNumber = 0;
                group.statusNumberObject.failedNumber = 0;
                group.statusNumberObject.cancelNumber = 0;
                // group.groupJobId = response;
                group.groupJobId = jobId;
                group.deviceCommonSpecifics.forEach(device => {
                  device.status = DeviceStatusEnum.WAITING;
                  device.jobId = group.groupJobId;
                });
                if (this.groupExpandedClone && group.groupId == this.groupExpandedClone.groupId) {
                  this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
                  this.groupExpandedClone.groupJobId = group.groupJobId;
                  this.groupExpandedClone.deviceCommonSpecifics.forEach(data => (data.jobId = group.groupJobId));
                  if (this.groupExpanded.activeColumnHeader != ActiveColumnHeader.TOTAL && this.groupExpanded.isExpand) {
                    this.groupExpanded.deviceCommonSpecifics = this.groupExpandedClone.deviceCommonSpecifics.filter(
                      device =>
                        device.status == Helper.getDeviceStatusEnum(this.groupExpanded.activeColumnHeader) &&
                        device.jobId.includes(Constant.DELIVERY_GROUP_KEY)
                    );
                  }
                }
              }
            });
          }
        }
      }

      if (deviceDeliverySingles.length > 0) {
        for (let i = 0; i < deviceDeliverySingles.length; i++) {
          let deviceId = deviceDeliverySingles[i];
          let payload = {
            device: this.deviceCommonSpecifics.find(data => data.id == deviceId).registrationId,
            account: this.getAccountId()
          };
          // let response = await this.callAPIForSingleDevice(payload, deviceId);
          let response = await this.callAPIForSingleDevice(deviceId.valueOf());

          if (response == this.DELIVERY_FAILED || !response[0].includes(Constant.DELIVERY_SINGLE_KEY)) {
            errorMessages = _.concat(
              errorMessages,
              `${this.deviceCommonSpecifics.find(data => data.id == deviceId).name}: ${this.translateService.instant(
                'dialog-delivery-announcement.delivery-failed'
              )}`
            );
          } else {
            let jobIdResponse = response[0].split(this.JOB_ID_SPLIT_KEY);
            let jobId = jobIdResponse[1];

            this.groupDevices.forEach(group => {
              group.deviceCommonSpecifics.forEach(device => {
                if (device.id == deviceId) {
                  device.status = DeviceStatusEnum.WAITING;
                  // device.jobId = response;
                  device.jobId = jobId;
                }
              });
            });
            this.groupExpandedClone?.deviceCommonSpecifics.forEach(element => {
              if (element && element.id == deviceId) {
                element.status = DeviceStatusEnum.WAITING;
                // element.jobId = response;
                element.jobId = jobId;
              }
            });
          }
        }
      }
      this.groupDevices.forEach(group => {
        group.deviceCommonSpecifics.forEach(device => {
          const index = checkedDevices.findIndex(data => data.id === device.id);
          if (index == -1) {
            return;
          }
          device.isDelivering = false;
          Helper.setDataForDeviceGroupCloneAnnoucement(
            Constant.IS_DELIVERY_ELEMENT,
            device.isDelivering,
            device.id,
            this.groupExpandedClone
          );
        });
      });
      let checkedDevicesIngroup = Helper.getCheckedDevicesInGroupCommonSpecific(
        checkedDevices.map(device => device.id),
        this.groupDevices,
        this.groupExpandedClone
      );
      this.groupExpandedClone?.deviceCommonSpecifics.forEach(data => (data.isDelivering = false));
      if (!this.dialogRef.componentInstance) {
        this.announcementDeliveryStatusService.saveDetailStatusForDevices(checkedDevicesIngroup).toPromise();
        this.dataService.sendData([Constant.DELIVERY_SUCCESS, checkedDevices]);
        return;
      }
      // Show message if an error occurs
      if (errorMessages.length > 0) {
        this.checkGroupExistsDeviceDeliveryGroup();
        this.updateStatusNumberForGroups(checkedDevicesIngroup, false);
        this.dialogService.showDialog(
          DialogSimpleSignageMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              texts: errorMessages
            }
          },
          () => {
            this.saveDetailStatusForDevices(checkedDevicesIngroup);
          }
        );
      } else {
        this.saveDetailStatusForDevices(checkedDevicesIngroup);
      }
    });
  }

  /**
   * save detail status for devices
   * @param devices
   */
  private saveDetailStatusForDevices(devices: DeviceCommonSpecific[]) {
    setTimeout(() => {
      this.announcementDeliveryStatusService.saveDetailStatusForDevices(devices).subscribe(
        () => {
          this.executingService.executed();
          this.handleUpdateStatusForDevices();
        },
        error => {
          this.executingService.executed();
          this.handleErrorMessage(error, 'update-status-for-devices-failed');
        }
      );
    }, 1000);
  }

  /**
   * Call API for single devices
   * Copied from dialog-delivery-timetable.component.ts -> dialog-delivery-simple.component.ts
   *
   * @param deviceId
   * @returns
   */
  private callAPIForSingleDevice(deviceId: number): Promise<Array<string> | string> {
    return new Promise(resolve => {
      // TODO: replace with new API
      this.announcementDeliveryStatusService.callAPIForSingleDevices([deviceId]).subscribe(
        data => {
          resolve(data);
        },
        error => {
          this.handleStatusForDevice(deviceId, DeviceStatusEnum.FAILED, null);
          resolve(this.DELIVERY_FAILED);
        }
      );
    });
  }
  /**
   * Call API for single devices
   * @param payload
   * @param deviceId
   * @returns
   */
  // private async callAPIForSingleDevice(payload: any, deviceId: any): Promise<any> {
  //   return new Promise<any>(resolve => {
  //     this.apiCustomerService.singleDelivery(payload).subscribe(
  //       data => {
  //         if (data) {
  //           this.deviceCommonSpecifics.forEach(device => {
  //             if (deviceId == device.id) {
  //               device.jobId = data[this.JOB_ID];
  //             }
  //           });
  //           resolve(data[this.JOB_ID]);
  //         } else {
  //           this.handleStatusForDevice(deviceId, DeviceStatusEnum.FAILED, null);
  //           resolve(this.DELIVERY_FAILED);
  //         }
  //       },
  //       () => {
  //         this.handleStatusForDevice(deviceId, DeviceStatusEnum.FAILED, null);
  //         resolve(this.DELIVERY_FAILED);
  //       }
  //     );
  //   });
  // }

  /**
   * get account id
   * @returns
   */
  private getAccountId(): any {
    return this.commonObject.tenantName?.length == this.LENGTH_ACCOUNT_OLD ? this.ACCOUNT_OLD : this.commonObject.tenantName.toUpperCase();
  }

  /**
   * Call API group devices
   * Copied from dialog-delivery-timetable.component.ts
   *
   * @param deviceDeliveryGroups
   * @returns
   */
  private callAPIForGroupDevices(deviceDeliveryGroup: DeliveryGroupDevice): Promise<Array<string> | string> {
    return new Promise(resolve => {
      // TODO: replace with new API
      this.announcementDeliveryStatusService.callAPIForGroupDevices([deviceDeliveryGroup]).subscribe(
        data => {
          resolve(data);
        },
        error => {
          this.handleStatusForCallAPIGroupWhenFailed(deviceDeliveryGroup);
          resolve(this.DELIVERY_FAILED);
        }
      );
    });
  }
  /**
   * Call API group devices
   * @param payload
   * @param deviceDeliveryGroup
   * @returns
   */
  // private async callAPIForGroupDevices(payload: any, deviceDeliveryGroup: DeliveryGroupDevice): Promise<any> {
  //   return new Promise<any>(resolve => {
  //     this.apiCustomerService.groupDelivery(payload).subscribe(
  //       data => {
  //         if (data) {
  //           this.deviceCommonSpecifics.forEach(device => {
  //             if (deviceDeliveryGroup.deviceIds.includes(device.id)) {
  //               device.jobId = data[this.JOB_ID];
  //             }
  //           });
  //           resolve(data[this.JOB_ID]);
  //         } else {
  //           this.handleStatusForCallAPIGroupWhenFailed(deviceDeliveryGroup);
  //           resolve(this.DELIVERY_FAILED);
  //         }
  //       },
  //       () => {
  //         this.handleStatusForCallAPIGroupWhenFailed(deviceDeliveryGroup);
  //         resolve(this.DELIVERY_FAILED);
  //       }
  //     );
  //   });
  // }

  /**
   * handle status for call api group
   * @param deviceDeliveryGroup
   */
  private handleStatusForCallAPIGroupWhenFailed(deviceDeliveryGroup: DeliveryGroupDevice): void {
    this.groupDevices.forEach(group => {
      if (group.groupId == deviceDeliveryGroup.groupId) {
        group.groupJobId = group.name;
        group.deviceCommonSpecifics.forEach(device => {
          device.status = DeviceStatusEnum.FAILED;
          device.detailStatusDisplay = null;
          device.jobId = group.name;
        });
      }
    });
    if (this.groupExpandedClone && this.groupExpandedClone.groupId == deviceDeliveryGroup.groupId) {
      this.groupExpandedClone.groupJobId = this.groupExpandedClone.name;
      this.groupExpandedClone.deviceCommonSpecifics.forEach(deviceCalendar => {
        deviceCalendar.status = DeviceStatusEnum.FAILED;
        deviceCalendar.detailStatusDisplay = null;
        deviceCalendar.jobId = this.groupExpandedClone.name;
      });
    }
  }

  /**
   * get CMP JobID When Delivery
   * @param device
   * @returns
   */
  private getCMPJobIDWhenDelivery(device: DeviceCommonSpecific, isFailed?: boolean): string {
    let groupDevice =
      this.groupExpandedClone && this.groupExpandedClone.groupId == device.groupId
        ? this.groupExpandedClone
        : this.groupDevices.find(group =>
            group.deviceCommonSpecifics.map(deviceCalendar => deviceCalendar.registrationId).includes(device.registrationId)
          );
    if (!groupDevice) {
      return '';
    }
    return groupDevice.isChecking ? groupDevice.name : isFailed ? '' : Constant.CMP_DELIVERY_SINGLE_KEY;
  }

  /**
   * save data devices checked when status is failed
   * @param checkedDevices
   * @param isNotShowError
   * @param isNotUncheckDevice
   * @returns
   */
  private async saveDevicesCheckedWhenStatusFailed(
    checkedDevices: DeviceCommonSpecific[],
    isNotShowError?: boolean,
    isNotUncheckDevice?: boolean
  ): Promise<void> {
    return new Promise(resolve => {
      const deviceCheckedIds = checkedDevices.map(device => device.id);
      this.handleDeviceDetailStatus(deviceCheckedIds, DeviceStatusEnum.FAILED, null);
      checkedDevices.forEach(device => {
        device.jobId = this.getCMPJobIDWhenDelivery(device, true);
      });
      if (this.groupExpandedClone) {
        this.groupExpandedClone.deviceCommonSpecifics.forEach(device => {
          let index = checkedDevices.findIndex(item => item.id == device.id && item.jobId == this.groupExpandedClone.name);
          if (index != -1) {
            device.jobId = this.groupExpandedClone.name;
          }
        });
      }
      this.updateStatusNumberForGroups(checkedDevices, true);
      this.updateGroupJobIdOfGroup(checkedDevices);
      this.checkGroupExistsDeviceDeliveryGroup();
      if (!isNotUncheckDevice) {
        this.handleCheckedAfterCancelDelivery();
      }

      this.announcementDeliveryStatusService.saveDetailStatusForDevices(checkedDevices).subscribe(
        () => {
          if (!isNotShowError) {
            this.handleError();
          }
          this.handleUpdateStatusForDevices();
          resolve();
        },
        error => {
          this.handleUpdateStatusForDevices();
          this.handleErrorMessage(error, 'update-status-for-devices-failed');
          resolve();
        }
      );
    });
  }

  /**
   * handle error
   *
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * handle checked after cancel delivery
   */
  private handleCheckedAfterCancelDelivery(): void {
    this.isCheckingAll = false;
    this.groupDevices.forEach(group => {
      group.isChecking = false;
      group.deviceCommonSpecifics.forEach(device => (device.isChecking = false));
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId) {
        this.groupExpandedClone.isChecking = group.isChecking;
        this.groupExpandedClone.deviceCommonSpecifics.forEach(device => (device.isChecking = group.isChecking));
      }
    });
  }

  /**
   * Update group job id of group
   *
   * @param checkedDevices
   */
  private updateGroupJobIdOfGroup(checkedDevices: DeviceCommonSpecific[]): void {
    const groupIdSet = [...new Set(checkedDevices.map(device => device.groupId))];
    this.groupDevices.forEach(group => {
      if (!group.groupId || !groupIdSet.includes(group.groupId)) {
        return;
      }
      group.groupJobId = group.deviceCommonSpecifics.some(device => device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
        ? group.groupJobId
        : group.name;
    });
  }

  /**
   * download data publish
   */
  public downloadDataPublish(): void {
    let checkedDevices = this.getDevicesChecked();
    // no device selected
    if (!checkedDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-delivery-announcement.choose-device')
        }
      });
      return;
    }
    const deviceIds = checkedDevices?.map(device => device.id);
    // check device don't set playlist
    this.announcementDeliveryStatusService.checkExistPlaylistOfCommonRegistration(deviceIds).subscribe(
      async isNotExistPlaylist => {
        if (isNotExistPlaylist) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant('dialog-delivery-announcement.no-set-playlist-download'),
                button1: this.translateService.instant('dialog-delivery-announcement.yes'),
                button2: this.translateService.instant('dialog-delivery-announcement.no')
              }
            },
            result => {
              if (result) {
                this.handleCheckSequenceAndTotalSizeForDownload(deviceIds, checkedDevices);
              } else {
                return;
              }
            }
          );
        } else {
          this.handleCheckSequenceAndTotalSizeForDownload(deviceIds, checkedDevices);
        }
      },
      async () => {
        error => Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * handle check sequence and total size for download
   * @param deviceIds
   * @param checkedDevices
   */
  public handleCheckSequenceAndTotalSizeForDownload(deviceIds: number[], checkedDevices: DeviceCommonSpecific[]): void {
    // check device set playlist but not sequence
    this.announcementDeliveryStatusService.checkExistDataSequence(deviceIds).subscribe(
      async isNotExist => {
        if (isNotExist) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-delivery-announcement.no-sequence')
            }
          });
          return;
        } else {
          // check total size of device > 100MB and > 1000 file audio mp3
          this.announcementDeliveryStatusService.validateTotalSizeOfDevices(deviceIds).subscribe(
            async datas => {
              if (datas?.length) {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    texts: [this.translateService.instant('dialog-delivery-announcement.maximum-file-size-download'), `${datas}`]
                  }
                });
                return;
              } else {
                this.handleDownloadPublish(checkedDevices);
              }
            },
            async () => {
              error => Helper.handleError(error, this.translateService, this.dialogService);
            }
          );
        }
      },
      async () => {
        error => Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * handle download publish
   * @param checkedDevices
   * @param deviceContentDays
   */
  private handleDownloadPublish(checkedDevices: DeviceCommonSpecific[]): void {
    const publishDeviceIdGroups = this.getPublishDeviceIdGroups(checkedDevices);
    // get list device id
    let devicesId = publishDeviceIdGroups.map(item => item.firstId);
    const devices = checkedDevices.filter(device => devicesId.includes(device.id));
    this.downloadPublishForDevices(devices, checkedDevices, publishDeviceIdGroups);
  }

  /**
   * download publish for devices
   * @param checkedDevices
   * @param allDevicesChecked
   * @param publishDeviceIdGroups
   */
  private downloadPublishForDevices(
    checkedDevices: DeviceCommonSpecific[],
    allDevicesChecked: DeviceCommonSpecific[],
    publishDeviceIdGroups: PublishDeviceIdGroup[]
  ): void {
    let dataSettings = checkedDevices.map(device => {
      return this.getDataPublishSettingForDevice(device, publishDeviceIdGroups, allDevicesChecked);
    });
    this.dialogService.showDialog(
      DialogDownloadDataComponent,
      {
        data: {
          dataSettings: dataSettings,
          type: 'announcement'
        }
      },
      () => this.handleCheckedAfterCancelDelivery()
    );
  }

  /**
   * get data publish setting for device
   * @param device
   * @param publishDeviceIdGroups
   */
  private getDataPublishSettingForDevice(
    device: DeviceCommonSpecific,
    publishDeviceIdGroups: PublishDeviceIdGroup[],
    allDevicesChecked: DeviceCommonSpecific[]
  ): PublishSetting {
    let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
    dataSetting.deviceId = device.id;
    dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    dataSetting.registrationId = device.registrationId;
    let index = publishDeviceIdGroups.findIndex(item => item.firstId == device.id);
    if (index != -1) {
      let devices = allDevicesChecked.filter(deviceData => publishDeviceIdGroups[index].ids?.includes(deviceData.id));
      dataSetting.registrationIdsOfOtherDevice = devices.map(data => data.registrationId);
    }
    return dataSetting;
  }

  /**
   * cancel checked job
   */
  public cancelDelivery(): void {
    let checkedDevices: Array<DeviceCommonSpecific> = this.getDevicesChecked();
    if (!checkedDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-delivery-announcement.choose-device')
        }
      });
      return;
    }
    // if (checkedDevices.some(device => device.isDelivering)) {
    //   this.dialogService.showDialog(DialogMessageComponent, {
    //     data: {
    //       title: this.translateService.instant('dialog-error.title'),
    //       text: this.translateService.instant('dialog-delivery-announcement.include-device-is-delivering')
    //     }
    //   });
    //   return;
    // }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            checkedDevices.length > 1
              ? this.translateService.instant('dialog-delivery-announcement.cancel-devices')
              : this.translateService.instant('dialog-delivery-announcement.cancel-device'),
          button1: this.translateService.instant('dialog-delivery-announcement.yes'),
          button2: this.translateService.instant('dialog-delivery-announcement.no')
        }
      },
      async result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
          this.clearAllIntervalDeliveryGroup();
          this.executingService.executing();
          this.announcementDeliveryStatusService.getDeviceInfo(checkedDevices.map(device => device.id).join(' ,')).subscribe(
            async deviceResult => {
              if (!deviceResult.length) {
                return;
              }
              // check diff data
              if (
                !Helper.isEqualDeviceCommonSpecific(checkedDevices, deviceResult) ||
                [...deviceResult].some(
                  data => data.jobId == this.getGroupNameByDevice(data) || data.jobId == Constant.CMP_DELIVERY_SINGLE_KEY
                )
              ) {
                // set jobId for device
                checkedDevices.forEach(checkedDevice => {
                  let index = deviceResult.findIndex(device => device.deviceId == checkedDevice.id);
                  if (index != -1) {
                    checkedDevice.jobId = deviceResult[index].jobId;
                  }
                });
                // get GroupSet
                const groupSet = Helper.getGroupAnnouncementSet(checkedDevices);
                // set groupJobId for group
                groupSet.forEach(data => {
                  const index = this.groupDevices.findIndex(group => group.groupId == data.groupId);
                  if (index != -1 && data.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
                    this.groupDevices[index].groupJobId = data.jobId;
                  }
                });
                // set data for groupClone
                if (this.groupExpandedClone) {
                  let i = groupSet.findIndex(group => group.groupId == this.groupExpandedClone.groupId);
                  if (i > -1) {
                    this.groupExpandedClone.groupJobId = groupSet[i].jobId;
                  }
                  this.groupExpandedClone.deviceCommonSpecifics.forEach(device => {
                    let j = deviceResult.findIndex(data => data.deviceId == device.id);
                    if (j != -1) {
                      device.jobId = deviceResult[j].jobId;
                    }
                  });
                }
                // if (
                //   [...deviceResult].some(
                //     device =>
                //       (device.jobId == this.getGroupNameByDevice(device) || device.jobId == Constant.CMP_DELIVERY_SINGLE_KEY) &&
                //       device.status == DeviceStatusEnum.WAITING
                //   )
                // ) {
                //   this.dialogService.showDialog(DialogMessageComponent, {
                //     data: {
                //       title: this.translateService.instant('dialog-error.title'),
                //       text: this.translateService.instant('dialog-delivery-announcement.include-device-is-delivering')
                //     }
                //   });
                //   this.executingService.executed();
                //   return;
                // }
              }
              // update latest status
              const devices = await this.updateLatestStatusForCheckedDevices(_.cloneDeep(checkedDevices));
              this.checkGroupExistsDeviceDeliveryGroup();

              if (!devices?.length) {
                this.executingService.executed();
                this.handleCheckedAfterCancelDelivery();
                this.handleUpdateStatusForDevices();
                return;
              }
              // cancel status of device: waiting or in progress
              // this.announcementDeliveryStatusService.cancelDelivery(devices.map(device => device.id)).subscribe(
              this.announcementDeliveryStatusService.cancelDelivery(checkedDevices.map(device => device.id)).subscribe(
                registrationIds => {
                  if (registrationIds.length) {
                    this.executingService.executed();
                    let errors = registrationIds.map(registrationId => {
                      return `${devices.find(data => data.registrationId == registrationId)?.name}: ${this.translateService.instant(
                        'dialog-delivery-announcement.cancel-failed'
                      )}`;
                    });
                    this.dialogService.showDialog(
                      DialogMessageComponent,
                      {
                        data: {
                          title: this.translateService.instant('dialog-error.title'),
                          texts: errors
                        }
                      },
                      () => {
                        this.handleUpdateStatusForDevices();
                      }
                    );
                  } else {
                    this.handleUpdateStatusForDevices();
                  }
                  // stop deliveryUpload() for cancelled devices
                  this.cancelledDeviceIds.push(...checkedDevices.map(device => device.id.valueOf()));
                  // do not use group delivery if the cancelled device belongs to a group
                  this.groupBreakdownNames.push(...new Set(checkedDevices.map(device => device.groupId)));
                  checkedDevices.forEach(device => {
                    device.status = DeviceStatusEnum.CANCELLED;
                    device.detailStatusCode = '';
                    device.detailStatusDisplay = '';
                  });
                  this.handleCheckedAfterCancelDelivery();
                  this.executingService.executed();
                },
                error => {
                  this.executingService.executed();
                  this.handleErrorMessage(error, 'cancel-delivery-failed');
                }
              );
            },
            error => this.handleErrorMessage(error, 'get-latest-device-information-failed')
          );
        }
      }
    );
  }

  /**
   * update latest status for checked devices
   * @param checkedDevices
   * @returns
   */
  private updateLatestStatusForCheckedDevices(checkedDevices: DeviceCommonSpecific[]): Promise<DeviceCommonSpecific[]> {
    return new Promise<DeviceCommonSpecific[]>(async resolve => {
      let devicesDeliverySingle = new Array<DeviceCommonSpecific>();
      let devicesDeliveryGroup = new Array<DeviceCommonSpecific>();
      checkedDevices.forEach(device => {
        if (!device.jobId) {
          return;
        }
        if (device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1) {
          devicesDeliverySingle.push(device);
        } else if (device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1) {
          devicesDeliveryGroup.push(device);
        }
      });

      // update status for devices delivery single
      let announcementDeliveryStatus = await this.updateLatestStatusForDevices(devicesDeliverySingle);
      if (announcementDeliveryStatus) {
        this.groupDevices.forEach(group => {
          group.deviceCommonSpecifics.forEach(deviceCalendar => {
            const dataResponse = announcementDeliveryStatus.find(item => item.registrationId == deviceCalendar.registrationId);
            if (dataResponse) {
              deviceCalendar.status = dataResponse.status;
              deviceCalendar.detailStatusDisplay = this.getDetailStatusOfDevice(dataResponse, deviceCalendar.status);
            }
          });
        });
        this.groupExpandedClone?.deviceCommonSpecifics.forEach(dv => {
          if (dv) {
            const deliveryStatus = announcementDeliveryStatus.find(item => item.registrationId == dv.registrationId);
            if (deliveryStatus) {
              dv.status = deliveryStatus.status;
              dv.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatus, dv.status);
            }
          }
        });
      }

      if (!devicesDeliveryGroup.length) {
        resolve(this.getDevicesCancelDelivery());
        return;
      }
      let promises = this.updateLatestStatusForDevicesDeliveryGroup(_.cloneDeep(devicesDeliveryGroup));
      await Promise.all([...promises]).then((jobIds: string[]) => {
        let cancelDevices = [];
        this.groupDevices.forEach(group => {
          group.deviceCommonSpecifics.forEach(deviceCalendar => {
            if (jobIds.includes(group.groupJobId) && deviceCalendar.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) != -1) {
              cancelDevices = _.concat(cancelDevices, deviceCalendar);
            }
            if (devicesDeliverySingle?.findIndex(device => device.id == deviceCalendar.id) != -1) {
              cancelDevices = _.concat(cancelDevices, deviceCalendar);
            }
          });
        });
        if (cancelDevices.length > 0) {
          resolve(cancelDevices?.filter(dv => dv.status == DeviceStatusEnum.WAITING || dv.status == DeviceStatusEnum.IN_PROGRESS));
        } else {
          resolve(this.getDevicesCancelDelivery());
        }
      });
    });
  }

  /**
   * update latest status for devices delivery group
   *
   * @param devices
   * @returns
   */
  private updateLatestStatusForDevicesDeliveryGroup(devices: DeviceCommonSpecific[]): Array<Promise<string>> {
    let activeColumnHeader = [
      ActiveColumnHeader.WAITING,
      ActiveColumnHeader.IN_PROGRESS,
      ActiveColumnHeader.COMPLETED,
      ActiveColumnHeader.CANCEL,
      ActiveColumnHeader.FAILED
    ];
    let jobIds: Array<string> = new Array<string>();
    devices.forEach(device => {
      if (device.jobId && !jobIds.includes(device.jobId)) {
        jobIds.push(device.jobId);
      }
    });
    return jobIds.map(jobId => {
      let devicesForJobId = this.groupDevices
        .find(group => group.groupJobId == jobId)
        ?.deviceCommonSpecifics?.filter(data => data?.jobId == jobId);
      let deviceCommonSpecificsForJobId = _.cloneDeep(devicesForJobId);

      return new Promise<string>(async resolve => {
        for (let i = 0; i < activeColumnHeader.length; i++) {
          if (!devicesForJobId?.length) {
            this.updateStatusNumberObject(jobId);
            resolve(jobId);
            return;
          }
          let status = activeColumnHeader[i];
          let payload = {
            jobId: jobId,
            status: Helper.getPayloadStatusEnum(status)
          };
          let registrationIds = await this.callAPIDeviceListForJob(
            payload,
            this.groupDevices.find(group => group.groupJobId == jobId)?.name
          );
          if (registrationIds) {
            let deviceStatus = Helper.getDeviceStatusEnum(status);
            this.groupDevices.forEach(group => {
              group.deviceCommonSpecifics.forEach(device => {
                let index = devicesForJobId.findIndex(deviceCalendar => deviceCalendar.registrationId == device.registrationId);
                if (registrationIds.find(item => item == device.registrationId) && index != -1) {
                  device.status = deviceStatus;
                  if (
                    device.status == DeviceStatusEnum.WAITING ||
                    device.status == DeviceStatusEnum.FAILED ||
                    device.status == DeviceStatusEnum.CANCELLED
                  ) {
                    device.detailStatusDisplay = Helper.handleShowDetailStatusDisplay(
                      this.translateService,
                      'dialog-delivery-announcement.detail-status',
                      device.status
                    );
                  }
                  devicesForJobId.splice(index, 1);
                }
              });
            });
            this.groupExpandedClone?.deviceCommonSpecifics.forEach(dv => {
              if (dv && registrationIds.find(item => item == dv.registrationId)) {
                dv.status = deviceStatus;
                if (
                  dv.status == DeviceStatusEnum.WAITING ||
                  dv.status == DeviceStatusEnum.FAILED ||
                  dv.status == DeviceStatusEnum.CANCELLED
                ) {
                  dv.detailStatusDisplay = Helper.handleShowDetailStatusDisplay(
                    this.translateService,
                    'dialog-delivery-announcement.detail-status',
                    dv.status
                  );
                }
              }
            });

            if (deviceStatus == DeviceStatusEnum.COMPLETED || deviceStatus == DeviceStatusEnum.IN_PROGRESS) {
              if (this.isShowDetailStatus) {
                let deviceCommonSpecifics = deviceCommonSpecificsForJobId.filter(deviceCalendar =>
                  registrationIds.includes(deviceCalendar.registrationId)
                );
                let simpleDeliveryStatus = await this.updateLatestStatusForDevices(deviceCommonSpecifics);
                if (simpleDeliveryStatus) {
                  this.updateDetailStatusForDevices(simpleDeliveryStatus);
                }
              }
              if (deviceStatus == DeviceStatusEnum.COMPLETED) {
                let devicesCompleted = this.deviceCommonSpecifics.filter(
                  device => registrationIds.includes(device.registrationId) && this.checkStatusOldDevices(devices, device.registrationId)
                );
                if (devicesCompleted.length) {
                  devicesCompleted.forEach(data => (data.status = DeviceStatusEnum.COMPLETED));
                  this.announcementDeliveryStatusService.saveDetailStatusForDevices(devicesCompleted).subscribe(
                    () => {},
                    error => this.handleErrorMessage(error, 'update-status-for-devices-failed')
                  );
                }
              }
            }
          }

          if (i == activeColumnHeader.length - 1) {
            this.updateStatusNumberObject(jobId);
            resolve(jobId);
            return;
          }
        }
      });
    });
  }

  /**
   * update status number object
   * @param jobId
   */
  private updateStatusNumberObject(jobId: string): void {
    this.groupDevices.forEach(group => {
      if (group.groupJobId && group.groupJobId == jobId) {
        group.statusNumberObject.waitingNumber = this.getStatusNumberObject(DeviceStatusEnum.WAITING, group);
        group.statusNumberObject.inprogressNumber = this.getStatusNumberObject(DeviceStatusEnum.IN_PROGRESS, group);
        group.statusNumberObject.completedNumber = this.getStatusNumberObject(DeviceStatusEnum.COMPLETED, group);
        group.statusNumberObject.cancelNumber = this.getStatusNumberObject(DeviceStatusEnum.CANCELLED, group);
        group.statusNumberObject.failedNumber = this.getStatusNumberObject(DeviceStatusEnum.FAILED, group);
        if (this.groupExpandedClone && this.groupExpandedClone.groupJobId == jobId) {
          this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
        }
      }
    });
  }

  /**
   * get status number object
   * @param status
   * @param group
   * @returns
   */
  private getStatusNumberObject(status: any, group: GroupDeviceAnnouncement): number {
    return group.deviceCommonSpecifics.filter(
      device => device.status == status && device.jobId && device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1
    ).length;
  }
  /**
   * get devices cancel delivery
   * @returns
   */
  private getDevicesCancelDelivery(): DeviceCommonSpecific[] {
    return this.getDevicesChecked()?.filter(dv => dv.status == DeviceStatusEnum.WAITING || dv.status == DeviceStatusEnum.IN_PROGRESS);
  }

  /**
   * check status old devices
   * @param devices
   * @param registrationId
   * @returns
   */
  private checkStatusOldDevices(devices: DeviceCommonSpecific[], registrationId: string): boolean {
    let device = devices.find(device => device.registrationId == registrationId);
    return device?.status && device.status != DeviceStatusEnum.COMPLETED;
  }

  /**
   * get Group Name By Device
   * @param device
   * @return Group Name
   */
  private getGroupNameByDevice(device: any): string {
    let groupDevice =
      this.groupExpandedClone && this.groupExpandedClone.deviceCommonSpecifics.map(data => data.id).includes(device.deviceId)
        ? this.groupExpandedClone
        : this.groupDevices.find(group => group.deviceCommonSpecifics.map(deviceCalendar => deviceCalendar.id).includes(device.deviceId));
    return groupDevice ? groupDevice.name : '';
  }

  /**
   * refresh Browser Device
   */
  public updateNow(): void {
    let checkedDevices = this.getDevicesChecked();
    if (!checkedDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-delivery-announcement.choose-device')
        }
      });
      return;
    }
    if (this.checkStatusDeviceUpdate(checkedDevices)) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`dialog-delivery-announcement.confirm-when-delivering`),
            button1: this.translateService.instant('dialog-delivery-announcement.yes'),
            button2: this.translateService.instant('dialog-delivery-announcement.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.handleUpdateNow(checkedDevices);
        }
      );
    } else {
      this.handleUpdateNow(checkedDevices);
    }
  }

  /**
   * checkStatusDeviceUpdate
   * @param checkedDevices
   * @returns
   */
  private checkStatusDeviceUpdate(checkedDevices: Array<DeviceCommonSpecific>): boolean {
    return checkedDevices.some(device => device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS);
  }

  /**
   * handle Update Now
   * @param checkedDevices
   */
  private handleUpdateNow(checkedDevices: Array<DeviceCommonSpecific>): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant(`dialog-delivery-announcement.confirm-refresh`),
          button1: this.translateService.instant('dialog-delivery-announcement.yes'),
          button2: this.translateService.instant('dialog-delivery-announcement.no')
        }
      },
      result => {
        if (!result) {
          return;
        }

        let registrationIds: string[] = checkedDevices?.map(device => device[this.REGISTRATION_ID_ELEMENT]);
        this.apiCustomerService.getCurrentStatusRequest(registrationIds).subscribe(
          res => {
            if (res[Constant.TIMEOUT_STATUS] && res[Constant.TIMEOUT_STATUS].length > 0) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('announcement-manager.title-error'),
                  text: this.translateService.instant('dialog-delivery-announcement.error-network')
                }
              });
              return;
            } else if (res[Constant.COMPLETE_STATUS] && res[Constant.COMPLETE_STATUS].length > 0) {
              if (res[Constant.COMPLETE_STATUS].length > 1) {
                const payload = {
                  devices: checkedDevices.map(device => device.registrationId),
                  account: this.commonObject.tenantName.toUpperCase()
                };
                this.apiCustomerService.updateNow(payload).subscribe(
                  () => {
                    this.handleCheckedAfterCancelDelivery();
                    this.notificationWhenUpdateNowIsSuccessful();
                  },
                  error => {
                    this.notificationWhenUpdateNowIsFails();
                  }
                );
              } else if (res[Constant.COMPLETE_STATUS] && res[Constant.COMPLETE_STATUS].length === 1) {
                const payload = {
                  device: checkedDevices[0].registrationId,
                  account: this.commonObject.tenantName.toUpperCase()
                };
                this.apiCustomerService.updateNow(payload).subscribe(
                  () => {
                    this.handleCheckedAfterCancelDelivery();
                    this.notificationWhenUpdateNowIsSuccessful();
                  },
                  error => {
                    this.notificationWhenUpdateNowIsFails();
                  }
                );
              }
            }
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('announcement-manager.title-error'),
                text: this.translateService.instant('dialog-delivery-announcement.error-network')
              }
            });
          }
        );
      }
    );
  }

  /**
   * notification When Update Now Is Successful
   */
  notificationWhenUpdateNowIsSuccessful(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('announcement-manager.title-success'),
        text: this.translateService.instant('announcement-manager.update-now-success')
      }
    });
  }

  /**
   * notification When Update Now Is Fails
   */
  notificationWhenUpdateNowIsFails(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('announcement-manager.title-error'),
        text: this.translateService.instant('announcement-manager.update-now-error')
      }
    });
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
    if (this.deviceCommonSpecifics.some(device => device.isDelivering)) {
      this.commonObject[this.GROUP_DEVICES] = _.cloneDeep(this.groupDevices);
    } else {
      this.commonObject[this.GROUP_DEVICES] = null;
    }
    this.dialogRef.close();
  }

  /**
   * update number status failed when delivery failed
   * @param group
   */
  private updateNumberStatusFailed(group: GroupDeviceAnnouncement) {
    if (group.deviceCommonSpecifics.every(device => device.jobId == group.name)) {
      group.statusNumberObject.failedNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.FAILED, group);
      group.statusNumberObject.waitingNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.WAITING, group);
    }
  }

  /**
   * get status number of group
   * @param status
   * @param group
   * @param isGroupChecked
   * @returns
   */
  private getStatusNumberOfGroup(status: any, group: GroupDeviceAnnouncement, isGroupChecked?: boolean): number {
    let groupDevice = group.name == this.groupExpandedClone?.name ? this.groupExpandedClone : group;
    if (isGroupChecked) {
      return groupDevice.deviceCommonSpecifics.filter(device => groupDevice.isChecking && device.status == status).length;
    }
    return groupDevice.deviceCommonSpecifics.filter(device => device.status == status).length;
  }

  /**
   * Is group Checked
   *
   * @param group
   * @param groupIds
   * @returns
   */
  private isGroupChecked(group: GroupDeviceAnnouncement, groupIds: Array<string>): boolean {
    if (this.groupExpandedClone && this.groupExpandedClone.groupId && this.groupExpandedClone.groupId == group.groupId) {
      return (
        groupIds.includes(this.groupExpandedClone.groupId) &&
        this.groupExpandedClone.deviceCommonSpecifics.every(device => device.isDelivering)
      );
    }
    return group.groupId && groupIds.includes(group.groupId) && group.deviceCommonSpecifics.every(data => data.isDelivering);
  }
}

export interface DialogData {
  groupDevices: Array<GroupDeviceAnnouncement>;
  commonTable: CommonTable;
}

export class DeliveryGroupIntervalObject {
  interval: any;
  groupId: string;
  constructor(interval: any, groupId: string) {
    this.interval = interval;
    this.groupId = groupId;
  }
}

/**
 * class DeviceAnnouncementPlaylist
 */
export class DeviceAnnouncementPlaylist {
  /**
   * device's name
   */
  deviceName: string;
  /**
   * announcementPlaylists
   */
  announcementPlaylists: Array<AnnouncementPlaylist>;

  constructor(deviceName: string, announcementPlaylists: Array<AnnouncementPlaylist>) {
    this.deviceName = deviceName;
    this.announcementPlaylists = announcementPlaylists;
  }
}

export class PublishDeviceIdGroup {
  /**
   * the first device id of the group
   */
  firstId: Number;
  /**
   * list of device ids with data similar to the first device
   */
  ids: Array<Number>;

  constructor(firstId?: Number, ids?: Array<Number>) {
    this.firstId = firstId;
    this.ids = ids;
  }
}

/**
 * Device delivery object
 */
export class DeviceDeliveryObject {
  deviceName: string;
  deviceId: Number;
  error: string;
  groupId: string;

  constructor(deviceId: Number, error: string, groupId: string, deviceName: string) {
    this.deviceId = deviceId;
    this.error = error;
    this.groupId = groupId;
    this.deviceName = deviceName;
  }
}

export class DeliveryGroupDevice {
  groupId: string;
  deviceIds: Array<Number>;
  constructor(groupId: string, deviceIds: Array<Number>) {
    this.groupId = groupId;
    this.deviceIds = deviceIds;
  }
}
